import {
  Switch,
  Route,
} from 'react-router-dom';
import React from 'react';
import App from './components/App';
import DashBoard from './admin/DashBoard';
import Login from './admin/Login';


export default () => (
  <Switch>
    <Route exact path="/" component={App} />
    <Route exact path="/search" component={App} />
    <Route component={Login} exact path="/admin/" />
    <Route exact path="/admin/*" component={DashBoard} />
  </Switch>
);
