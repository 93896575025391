import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from "@material-ui/core";
import SaveAltSharp from "@material-ui/icons/SaveAltSharp";

import React, { useEffect } from "react";
import Api from "../lib/Api";

const MenuCsvExport = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [exportRowCount, setExportRowCount] = React.useState(0);

  const refresh = () => {
    Api.getAllPastExports().then(setRows);
    Api.getTotalRowCountForIncrementalExport().then((data) => {
      setExportRowCount(data.no_of_rows);
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Box>
      <Box>
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              Api.getCSVDisplay(null);
            }}
            type="button"
          >
            Full Export
          </Button>
        </Box>
        <Box justifyContent="space-between" width="500px" display="flex">
          <h1>Past incremental exports </h1>
        </Box>
        <Box>
          <Table>
            <TableHead>
              <TableCell>Generated Date</TableCell>
              <TableCell>Generated By</TableCell>
              <TableCell>Rows</TableCell>
              <TableCell>Download</TableCell>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.export_date_time}>
                    <TableCell>{row.export_date_time}</TableCell>
                    <TableCell>{row.export_user}</TableCell>
                    <TableCell>{row.rows_count}</TableCell>
                    <TableCell>
                      <IconButton
                        disabled={isLoading}
                        onClick={() => {
                          Api.getCSVDisplayDated(row.id);
                        }}
                      >
                        <SaveAltSharp color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Box mt={4}>
          <Button
            disabled={isLoading}
            onClick={() => {
              setLoading(true);
              Api.doIncrementalExport()
                .then((result) => {
                  console.log(result);
                  if (result.message) {
                    alert(result.message);
                  }
                  refresh();
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  alert(err);
                });
            }}
            variant="contained"
            color="primary"
            type="button"
          >
            Incremental Export {exportRowCount} rows
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuCsvExport;
