import React from 'react';
import {
  Box, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, TextareaAutosize,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,

} from '@material-ui/pickers';
import moment from 'moment';
import Api from '../lib/Api';


export default () => {
  const [fromDate, setFromDate] = React.useState(moment().format('yy-MM-DD'));
  const [tillDate, setTillDate] = React.useState(moment().format('yy-MM-DD'));
  const [rows, setRows] = React.useState([]);

  const handleList = () => {
    Api.getApiCallLogs(fromDate, tillDate).then(setRows);
  };


  return (
    <Box>

      <Box justifyContent="space-between" width="500px" display="flex">
        <Box>
          <TextField
            id="date"
            label="From Date"
            type="date"
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            value={fromDate}

            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box>
          <TextField
            id="date"
            label="Till Date"
            type="date"
            value={tillDate}
            onChange={(e) => {
              setTillDate(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Button onClick={handleList} variant="contained" color="primary" type="button">Show Logs</Button>
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableCell>Call From Date</TableCell>
            <TableCell>Call till Date</TableCell>
            <TableCell>Api</TableCell>
            <TableCell>Response</TableCell>
            <TableCell>Error</TableCell>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow>
                <TableCell>{row.param_start_date}</TableCell>
                <TableCell>{row.param_end_date}</TableCell>
                <TableCell>{row.api}</TableCell>
                <TableCell><TextareaAutosize readOnly rowsMax={10} value={row.result} /></TableCell>
                <TableCell>{row.error}</TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
