/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, Button, Box, DialogActions, DialogContentText, TextField,
} from '@material-ui/core';

export default class Prompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '', body: '', promptText: '',
    };
  }

  setOpen(bool) {
    this.setState({ open: bool });
  }

  open(title, body, onClose, yes = () => {}) {
    this.setState({
      title, body, onClose, promptText: '',
    });
    this.setOpen(true);
  }


  render() {
    return (
      <Dialog open={this.state.open}>
        <DialogTitle>{this.state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.body}
          </DialogContentText>
          <TextField
            value={this.state.promptText}
            onChange={(e) => this.setState({ promptText: e.target.value })}
            id="outlined-basic"
            label={this.state.title}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              this.state.onClose(this.state.promptText);
              this.setOpen(false);
            }}
          >
            OK
          </Button>

        </DialogActions>
      </Dialog>
    );
  }
}
