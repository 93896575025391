import React,{useState} from 'react'
import styled from 'styled-components';
import _ from 'lodash'
import { ASSETS_SERVER, SERVER } from '../lib/Api';



const SubCatHeader=styled.div`
  padding: 20px;
 
    display:flex;
 
     `;


const SectionHeader=styled.div`
    padding: 20px;
    cursor: pointer;
    display:flex;
    justify-content:space-around;
    border-bottom: 1px solid;`;


const SectionHeaderMain=styled(SectionHeader)`

`;


const SectionHeaderText_Normal=styled.span`
border-color: #434448;
    
    font-weight: 400;
    cursor: pointer;
    font-size: 20px;
    width:100%;
  

`;

const SectionHeaderText=styled.a`
border-color: #434448;
    color: #005e9d;
    font-weight: 400;
    cursor: pointer;
    font-size: 20px;
    width:100%;
    :hover{
        text-decoration:underline;
    }

`;

const ArrowIcon=styled.i`display: inline-block;
font: normal normal normal 20px/1 "Optum-Micro-interaction-16";
font-weight: 700;
padding: 5px;
color: white;
transform:rotate(${props=>props.expanded?180:0}deg);
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
transition: transform 300ms ease-in-out;`;

const ArrowIconHolder=styled.span`
    background: #005e9d;
    border-color: #434448;
    text-align: center;
    height: 30px;
    margin-left: 30px;
    width: 30px;
   border-radius: 50%;
    color: white;

`;


export default ({orders,archived_orders})=>{

    let main_folders=_.groupBy(orders,a=>a.main_folder);
    let main_archived_folders=_.groupBy(archived_orders,a=>a.main_folder);
    const [archivedCollasped,setArchiveCollasped]=useState(true);
    
    




 
    return  (<div>
        <SectionHeader   >
                    <SectionHeaderText_Normal>EWS</SectionHeaderText_Normal>    
                   
        </SectionHeader>
       {<>{_.map(main_folders,(values,key)=>{
           
           return <Items key={key} main_folder={key} orders={values} />;
       })}</>  }
         {archived_orders.length>0&&<SectionHeader onClick={()=>{
             setArchiveCollasped(!archivedCollasped)
         }}  >
                    <SectionHeaderText_Normal>Archive</SectionHeaderText_Normal> 
                    <ArrowIconHolder> <ArrowIcon expanded={!archivedCollasped}></ArrowIcon>  </ArrowIconHolder>   
                    
        </SectionHeader>}
        {archivedCollasped==false && <>{_.map(main_archived_folders,(values,key)=>{
           
           return <Items key={key} main_folder={key} orders={values} />;
       })}</>  }
        </div>);
}




const ItemsHeader=styled.div`
padding-left: 10px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    font-size: 20px;
    background: #efefee none top left no-repeat scroll;
    min-height: 36px;`;


const ItemsCointainer=styled.div`
padding-left: 20px;
margin-bottom:0px;
`;

const AreaBox=styled.div`
background-color:rgba(239,239,238,0.4);
padding:10px;
padding-left: 20px;
margin-top:20px;
`;

const AreaBoxHeader=styled.div`
margin: 20px 20px;
font-weight: bold;
color: #282a2e;
font-size: 18px;

`;


    const ItemBoxStyle=styled.a`
    box-sizing: border-box;
    cursor:pointer;
    margin-top: 0;
    margin-bottom: 15px;
    margin-bottom: 15px;
    padding-right: 15px;
    border-radius: 4px;
    text-decoration:none;
   
    border: 1px solid #d0d0ce;
    border-bottom: 3px solid #d0d0ce;
    
    width:49%;
    background-color:#efefee;
    transition: background-color  300ms ease-in-out;
    display:flex;
    @media (max-width: 767px) {
        width:100%;
    }
    
    :hover{
        background-color:#dfdfdd;
    }

    `;


const ItemImage=styled.figure`
height: 100px;
    width: 120px;
    margin: 20px;
    background-image: url('${props=>props.path}');
    background-size: cover;


`;


const ItemInfo=styled.span`
flex:1;
padding:10px;
display:flex;
flex-direction:column;
justify-content:center;
 


`;

const ItemInfoL1=styled.div`
    
 font-size: 18px;
    font-weight: 700;
    margin: 12px 0;
    color: #282a2e;

`;

const ItemInfoL2=styled.span`
align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin-top:10px;
    text-decoration: none;
    pointer-events: none;
    color: #005e9d;
 

`;

const ItemsHolder=styled.div`
  @media (min-width: 767px) {
    display:flex;
    flex-wrap:wrap;
    padding-left:20px;
    padding-right:20px;
    
    justify-content:space-between;
        
}



`;

const ItemBox=({order})=>{

    if(order.archived!=='Y')
    {
    return (<ItemBoxStyle href={`${ASSETS_SERVER+order.pdf_local_path}`} target="_blank">
        <ItemImage path={`${ASSETS_SERVER+order.icon_path}`} />
        <ItemInfo>

<ItemInfoL1>{order.product_name}-{order.language}</ItemInfoL1>
<ItemInfoL2>Preview/Download > </ItemInfoL2>
        </ItemInfo>

</ItemBoxStyle>);
    }
    else
    {
    return     (<ItemBoxStyle href={`${SERVER+'/getFromArchive.php?file='+order.pdf_local_path}`} target="_blank">
        <ItemImage path={`${SERVER+'/getFromArchive.php?image='+order.icon_path}`} />
        <ItemInfo>
<ItemInfoL1>{order.product_name}</ItemInfoL1>
<ItemInfoL2>Preview/Download > </ItemInfoL2>
        </ItemInfo>

</ItemBoxStyle>);
    }
}

/**
 * 
 * @param {{orders:[{
 *  id:Number,
 * order_id:Number,
 * country:String,
 * product_name:String,
 * pdf_remote_path:String,
 * icon_path:String,
 * pdf_local_path:String
 * 
 * }]}} param0 
 */
const Items=({main_folder,orders})=>{


    const orderGroup=_.groupBy(orders,'country');
    const countries= _.keys(orderGroup).sort();
   const [expanded,setExpanded]=useState(false);


    return (<ItemsCointainer>
        
        <SectionHeaderMain onClick={()=>setExpanded(!expanded)} >
                    <SectionHeaderText>{main_folder}</SectionHeaderText>    
                   <ArrowIconHolder> <ArrowIcon expanded={expanded}></ArrowIcon>  </ArrowIconHolder>
        </SectionHeaderMain>
        
        
    {expanded && countries.map(country=>{
        const countryOrders=orderGroup[country];
            return (<CountryBox country={country} countryOrders={countryOrders} key={country} /> );

    })}

    </ItemsCointainer>);
}


const CountryBox=({country,countryOrders})=>{


    const subCatGroup=_.groupBy(countryOrders,a=>a.sub_folder_1);
    console.log(subCatGroup);


    const [expanded,setExpanded]=useState(false);
    
    return (<AreaBox key={country}>
<SectionHeader onClick={()=>setExpanded(!expanded)} >
                    <SectionHeaderText>{country}</SectionHeaderText>    
                   <ArrowIconHolder> <ArrowIcon expanded={expanded}></ArrowIcon>  </ArrowIconHolder>
        </SectionHeader>

      
                    {expanded && _.map(subCatGroup,(value,key)=>{

return <div>
     <SubCatHeader>{key}</SubCatHeader>    
    <ItemsHolder key={key}>

                   
{value.map(order=>{

return <ItemBox key={order.id} order={order} />
})}
   
   

</ItemsHolder></div>

                    })  }
            </AreaBox>);
}

