import React from "react";
import styled from 'styled-components';
import Constants from "./Constants";

const GreyRule=styled.div`
width: 100%;
margin-top: 40px;
margin-bottom: 20px;
border-bottom: 10px solid #888b8d;`;


const CRMessage=styled.div`
font-weight: normal;
line-height: 1.5;
margin-bottom: 24px;
font-size:14px;
`;

const TOU=styled.a`
color: #005e9d;
font-size: 14px;
text-decoration: none;`;

export default ()=>{

    return (<React.Fragment>
        <GreyRule/>
        <CRMessage>
        ©2022 Optum, Inc. All Rights Reserved. Do not reproduce, transmit or modify any information or content on this website in any form or by any means without the express written permission of Optum.
        </CRMessage>
        <TOU href="https://fulfillmentservices.optum.com/PURL/PURLTermsofUse.aspx" target="_blank">Terms of Use</TOU></React.Fragment>);
}