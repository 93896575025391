/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
} from 'react';
import {
  TextField, Box,
  Typography, Dialog, DialogTitle, DialogContent, Button, DialogActions,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import Constants from './Constants';


export default ({
  param_country, open, afterClose, saveAction, mode,
}) => {
  const times = _.times(7);
  if (param_country === null) {
    return null;
  }

  const orgi_contract=param_country.contracts[0];
  const [country, setCountry] = useState(_.cloneDeep(param_country));


  const changeText = (newText, field) => {
    const modifiedCountry = { ...country };
    modifiedCountry[field] = newText;
    setCountry(modifiedCountry);
  };


  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <Typography variant="subtitle1" color="primary">
          {mode === Constants.MODE_ADD_NEW ? 'Add New Country' : ` Edit Country ${param_country.COUNTRY}`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>

          <Grid item md={6}>
            <TextField
              onChange={(e) => {
                changeText(e.target.value, 'COUNTRY');
              }}
              label="Country"
              value={country.COUNTRY}
            />
          </Grid>

          <Grid item md={6}>
            <TextField
              onChange={(e) => {
                changeText(e.target.value, 'LIVEWELL_ACCESS_CODE');
              }}
              label="Livewell Access code"
              value={country.LIVEWELL_ACCESS_CODE}
            />
          </Grid>


          <Grid item md={6}>
            <TextField
              onChange={(e) => {
                changeText(e.target.value, 'BIOMETRICS_URL');
              }}
              label="Biometrics url"
              value={country.BIOMETRICS_URL}
            />
          </Grid>


          {times.map((num, counter) => (
            <React.Fragment key={num}>
              <Grid item md={6}>
                <TextField
                  onChange={(e) => {
                    changeText(e.target.value, `Phone_${counter + 1}`);
                  }}
                  value={country[`Phone_${counter + 1}`]}
                  label={`Phone ${num + 1}`}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  onChange={(e) => {
                    changeText(e.target.value, `Phone_Label_${num + 1}`);
                  }}
                  value={country[`Phone_Label_${counter + 1}`]}
                  label={`Phone Label ${num + 1}`}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <hr />
        <Typography variant="subtitle1" color="primary">
          Contract
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <TextField 
             
              onChange={(e) => {
                country.contracts[0]=e.target.value;
                const modifiedCountry = { ...country };
                setCountry(modifiedCountry);
              }}
              label="Contract Id"
              value={country.contracts[0]}
            />
          </Grid>
        </Grid>


      </DialogContent>
      <DialogActions>
        <Box textAlign="center" marginTop={5}>
          <Button

            onClick={() => {
              country.orgi_contract=orgi_contract;
              saveAction(country);
            }}


            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              afterClose();
            }}
            color="secondary"
          >
            Cancel
          </Button>

        </Box>
      </DialogActions>
    </Dialog>
  );
};
