import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  FormControl, Button, Table, TableHead, TableRow, TableCell, TableBody, Link, Typography, Paper, CircularProgress,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

import DeleteIcon from '@material-ui/icons/ArchiveSharp';
import RestoreIcon from '@material-ui/icons/Restore';
import { green } from '@material-ui/core/colors';
import copy from 'copy-to-clipboard';

import _ from 'lodash';
import Api, { ASSETS_SERVER } from '../lib/Api';
import { Autocomplete } from '@material-ui/lab';


export default () => {
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [selectedClient, setSelectedClient] = useState({});
  const [url, setUrl] = useState('http://');
  const [alertMessage, setAlertMessage] = useState(null);
  const [orders, setOrders] = useState([]);
  const [archiving, setArchiving] = useState(0);


  const archiveOrder = (order) => {
    setArchiving(order.id);
    Api.archive_order({ order_id: order.id, action: order.archived == 'Y' ? 'N' : 'Y' }).then(() => {
      order.archived = order.archived == 'Y' ? 'N' : 'Y';
    }).finally(() => {
      setArchiving(null);
    });
  };


  useEffect(() => {
    Api.getRemoteClientList().then(setClients).catch((err) => {
      alert(err);
    });
  }, []);

  const clientChanged = (event,item) => {
      

    setSelectedClientId(item.id);
    const selectedClient = item
    setSelectedClient(selectedClient);
    if(selectedClient.access_token)
    {
    setUrl(`${location.protocol}//${location.host}?token=${selectedClient.access_token}`);
    }
    else
    {
      setUrl('.....');
    }
   //setUrl(selectedClient.Library_PURL||'.....');
    Api.orderByClient(selectedClient.id).then(setOrders).catch((err) => {
      alert(`${err}`);
    });
  };


  const doCopy = () => {
    copy(`${location.protocol}//${location.host}?token=${selectedClient.access_token}`);
    setAlertMessage('Copied!!');
  };


  const getOrders = () => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            Order #
          </TableCell>
          <TableCell>
            Date
          </TableCell>
          <TableCell>
            Country
          </TableCell>
          <TableCell>
            Language
          </TableCell>
          <TableCell>
            PDF URL
          </TableCell>
          <TableCell>
            Product id
          </TableCell>
          <TableCell>
            Product
          </TableCell>
          <TableCell>
            Icon
          </TableCell>
          <TableCell>
            Email
          </TableCell>

          <TableCell>
            ...
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order) => (
          <TableRow>
            <TableCell>{order.order_id}</TableCell>
            <TableCell>{order.order_date_time}</TableCell>
            <TableCell>{order.country}</TableCell>
            <TableCell>{order.language}</TableCell>
            <TableCell><Link target="_blank" rel="noreferrer" href={order.pdf_remote_path}>PDF</Link></TableCell>
            <TableCell>{order.product_id}</TableCell>
            <TableCell>{order.product_name}</TableCell>
            <TableCell><img style={{ width: 100 }} src={`${ASSETS_SERVER + order.icon_path}`} /></TableCell>
            <TableCell>{order.order_email}</TableCell>
            <TableCell>
              <Button
                onClick={() => {
                  archiveOrder(order);
                }}
                disabled={archiving === order.id}
                size="small"
                variant="contained"
                color={order.archived == 'Y' ? 'primary' : 'secondary'}

                startIcon={archiving === order.id ? <CircularProgress size={20} /> : (order.archived == 'N' ? <DeleteIcon /> : <RestoreIcon />)}
              >
                {order.archived == 'N' ? 'Hide' : 'Show'}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );


  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <FormControl style={{ width: '100%' }} variant="outlined">
           
            { /* }
            <InputLabel>Select Client</InputLabel>
           <Select labelWidth={100} value={selectedClientId} onChange={clientChanged}>
              <MenuItem disabled value="">
                <em>...</em>
              </MenuItem>
              {clients.map((client) => <MenuItem value={client.client_name} key={client.id}>{client.client_name}</MenuItem>)}
            </Select>
  */}

 
            <Autocomplete
            popoverProps={{ style: { width: 'auto' } }}
            style={{ width: '100%' }}
            onChange={clientChanged}
            value={selectedClient}
            options={clients}
            disableClearable
            autoSelect
            renderOption={(option) =>option.CLIENT_NAME}
            getOptionLabel={(option) => option.CLIENT_NAME}
            renderInput={(params) => <TextField fullWidth {...params} label="Select Client" variant="outlined" />}
          />



          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField disabled style={{ width: '100%' }} label="Access Token" value={selectedClient.access_token || '...'} variant="outlined" />
        </Grid>
        <Grid item md={11}>
          <TextField disabled style={{ width: '100%' }} label="URL" value={url} variant="standard" />
        </Grid>
        <Grid item md={1} justify="center">
          <Button onClick={doCopy} variant="outlined" color="primary">Copy</Button>
        </Grid>
      </Grid>
      <Box>
        <Grid container style={{ marginTop: 10 }}>
          <Grid Item m={1} md={4}>
            {' '}
            Total Order:
            {orders.length}
          </Grid>
          <Grid Item m={1} md={4}>
            {' '}
            Hidden Order:
            {_.compact(orders.map((a) => a.archived === 'Y')).length}
          </Grid>
          <Grid Item m={1} md={4}> Last  Order: 05/05/2019 12:11 PM</Grid>

        </Grid>
        <Box mt={2}>

          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Orders
          </Typography>
          <Paper style={{ marginTop: 20 }}>

            {getOrders()}
          </Paper>
        </Box>
      </Box>
      <Snackbar
        style={{ backgroundColor: green[800] }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => {
          setAlertMessage(null);
        }}
        open={alertMessage != null}
        autoHideDuration={2000}


        message={<span id="message-id">{alertMessage}</span>}
      />
    </Container>
  );
};
