import md5 from 'blueimp-md5';
import _ from 'lodash';

let SERVER = '/server';
if (process.env.NODE_ENV === 'development') {
  SERVER = 'http://localhost:8888/MRC/server';
}

const USER_BROWSER_TOKEN = 'USER_BROWSER_TOKEN';


const BUILD = process.env.BUILT_FOR;
let ASSETS_SERVER = 'https://mrc.mbyte.com/server/files/';
if (BUILD === 'LIVE') {
  ASSETS_SERVER = 'https://mrc.healthtek.us/server/files/';
}
 
console.log("ASSETS_SERVER",ASSETS_SERVER);

export {
  BUILD, ASSETS_SERVER,
};
function s4() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}


/*
  returns unique GUID
  */
function guid() {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}


export default class Api {
  static async getClientByToken(token) {
    return Api.request('GET_CLIENT_BY_TOKEN', { token });
  }





  static async archive_order({ order_id, action }) {
    return Api.request('order_action', { order_id, action });
  }


  static async searchItems({ searchtext, client_id }) {
    return Api.request('SEARCH_ORDERS', { searchtext, client_id });
  }

  static uuid() {
    let uuid = localStorage.getItem(USER_BROWSER_TOKEN);
    if (uuid != null) {
      return uuid;
    }
    uuid = guid();
    localStorage.setItem(USER_BROWSER_TOKEN, uuid);
    return uuid;
  }

  static async orderByClient(client_id) {
    return Api.request('order_by_client', { client_id });
  }


  static async saveClientData(orgiClientId, client) {
    return Api.request('remote_save_client', { ...client, orgiClientId });
  }

  static async insertRemoteCountry(insertObj) {
    return Api.request('remote_insert_country', insertObj);
  }

  static async updateRemoteCountry(updateObj) {
    return Api.request('remote_update_country', updateObj);
  }

  static async deleteRemoteCountry(Country_ID) {
    return Api.request('remote_delete_country', { Country_ID });
  }

  static async deleteClient(client_id)
  {
    return Api.request('remote_delete_client', { client_id });
  }

  static async getRemoteCountriesForClient(client_id) {
    return Api.request('remote_countries', { client_id });
  }

  static async getRemoteClientList() {
    return Api.request('remote_clients', {});
  }

  static async getClientList() {
    return Api.request('get_client_list', {});
  }

  static async admin_logout() {
    return Api.request('admin_logout', {});
  }

  static async getErrorList()
  {
    return Api.request('admin_get_error_list',{});
  }


  static async getAllPastExports()
  {
    return Api.request('ADMIN_GET_INCREMENTAL_EXPORT',{});
  }

  static async getTotalRowCountForIncrementalExport()
  {
        const response= Api.request('ADMIN_GET_ROW_COUNT_FOR_DISPLAY',{});
        return response

  }



  static async getApiCallLogs(fromDate,tillDate)
  {
    return Api.request('admin_api_call_logs',{fromDate,tillDate});
  }
  
  static async admin_login({ username, password }) {
    if (!username || !password) {
      throw new Error('Username or password is blank!');
    }
    /*
    //TODO
    move away from plain md5
    */
    const epassword = md5(password);
    return Api.request('admin_login', { username, password: epassword });
  }


  static async getOrders() {
    return Api.request('GET_ALL_ORDERS', {});
  }


  static getCSVDisplay(product_id)
  {
    window.open(`${SERVER}/services/admin_get_csv_display_order.php?product_id=${product_id}`);
  }



  static getCSVDisplayDated(product_id)
  {
    window.open(`${SERVER}/services/admin_get_pre_download.php?id=${product_id}`);
  }




  static doIncrementalExport()
{
    return Api.request('ADMIN_INCREMENTAL_EXPORT', {});
}


  static   getCSV()
  {
    window.open(`${SERVER}/services/getCSVClient.php`);
  }

 static async uploadFile(body)
 {
   debugger
    body.append('ACTION', 'UPLOAD_LOGO')
   return Api.request('UPLOAD_LOGO',body,true)
 }
  
  static async request(ACTION, pbody, formField = false) {
    const body = pbody || {};
    if (formField === false) {
      body.ACTION = ACTION;
      body.uuid = Api.uuid();
    }
    const resp = await fetch(`${SERVER}/bootstrap.php`, {
      method: 'POST',

      body: formField === false ? JSON.stringify(body) : body,
    });

    if (resp.headers.get('content-length') == '0') {
      throw new Error('Your session has timed out please login again.');
    }

    const val = await resp.json();
    if (val?.SESSION_OUT === true) {
      // location.replace("/admin");
    }

    return val;
  }
}

export {
  SERVER,
};
