import React,{useState} from 'react'
import optumLogo from '../assets/logo_optum_2023.png'
import styled from 'styled-components';
import Constants from "./Constants";
import { SERVER } from '../lib/Api';

const Header=styled.section`
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    justify-content:space-between;
`;

const SearchBar=styled.form`
    display:flex;
    align-items:center;

`;

const SearchText=styled.input`
-webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    height: 44px;
    font-size: 14px;
    outline: 0;
    padding: 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance: none;`;

const SearchButton=styled.button`
height: 44px;
cursor: pointer;
    vertical-align: middle;
    padding: 0 10px;
    font-size: 14px;
    color: #fff;
    background-color: #888b8d;
    border: 0;`;


const SubHeader=styled.div`
display:flex;
flex-wrap:wrap;
flex:1;
align-items:center;
`;


const Logo=styled.img`
width: auto;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    margin-right:20px;
  

    
`;


    const Title=styled.div`
    font-weight: 300;
    text-align: left;
    font-size: 24px;
    color: #282a2e;
    text-rendering: optimizeLegibility;
    margin: 0 0 -24px 0;
    @media (max-width:500px) {
        margin: 0 0 24px 0;
         
    }


    `;

    const ClientLogo=styled.img`
    max-width:200px;
    max-height:50px;
    margin-right:20px;
    
    `;


export default ({client,searchQuery})=>{

    const [searchText,setSearchText]=useState(searchQuery||'');

    const doSubmit=(e)=>{
                
            if(searchText.trim().length<=0)
            {
                e.preventDefault();
                return;
            }
      
    }


return ( <Header>
    <SubHeader>
     <Logo src={optumLogo} /> 
    <Title>| {client.CLIENT_NAME}</Title>
    </SubHeader>
    <div>
        
    <SearchBar onSubmit={doSubmit} action={'/search'}>
    <ClientLogo onError={e=>{
        e.currentTarget.style.visibility='hidden';
    }} src={SERVER+"/logos/"+client.LOGO+".jpg?rand="+Math.random()} />
    <input type="hidden" name="token" value={client.access_token}/>
        <SearchText name="q" 
        onChange={e=>setSearchText(e.target.value)}
        value={searchText} placeholder="search" autoComplete="search" />
      
        <SearchButton>Search</SearchButton>
    </SearchBar>
    </div>
</Header>);

}