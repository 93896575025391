/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  Container, TextField, Box, FormControl,
  Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, Dialog, DialogTitle, DialogContent, Button, Backdrop, CircularProgress, DialogActions,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AddBoxTwoTone from '@material-ui/icons/AddBoxTwoTone';

import Api, { SERVER } from '../lib/Api';
import BooleanAlert from './BooleanAlert';
import Alert from './Alert';
import EditCountry from './EditCountry';
import Constants from './Constants';
import FindInPage from '@material-ui/icons/FindInPage';
import ClientFinder from './ClientFinder';




const DEFAULT_COUNTRY = {
  COUNTRY: '',
  LIVEWELL_ACCESS_CODE: '',
  Primary_Client_Logo_Global: '',
  TDD_TTY: '',
  BIOMETRICS_URL: '',
  Country_ID: '',
  contracts:['']
};


export default () => {
  const [clients, setClients] = useState([]);
  const [selectedCountry,setSelectedCountry]=useState(null);


  const [countries, setCountries] = useState([]);

  const [selectedClient, setSelectedClient] = useState({});
  const [clientToEdit, setClientToEdit] = useState();


  //const [selectedCountry, setSelectedCountry] = useState(null);
  const [actionMode, setActionMode] = useState(Constants.MODE_UPDATE);
  const [orgiClientId, setOrgiClientId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showClientFinder,setShowClientFinder]=useState(false);


  const booleanAlert = useRef(null);
  const alert = useRef(null);


  const getRemoteClientList = () => {
    setLoading(true);
    Api.getRemoteClientList().then((pclients) => {
      pclients.unshift({ CLIENT_NAME: 'Add New', Client_ID: Constants.NEW_ID });
      setClients(pclients);
    }).catch((err) => {
      // eslint-disable-next-line no-alert
      alert.current.ok(`${err}`);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    getRemoteClientList();
  }, []);

  const startEditingClientData = () => {
    setClientToEdit({ ...selectedClient });
  };


  const getCountrys = (client) => {
    setOrgiClientId(client.Client_ID);
    if (client.Client_ID === Constants.NEW_ID) {
      setCountries([]);
      setSelectedClient({});
      setClientToEdit({
        CLIENT_NAME: '',
        Client_ID: '',
        PARENT_COMPANY: '',
        Country_ID: '',

      });
    } else {
      setLoading(true);
      setSelectedClient(client);
      //debugger;
      Api.getRemoteCountriesForClient(client.id).then((countries) => {
        setCountries(countries);
      }).catch((err) => {
        // eslint-disable-next-line no-alert
        alert.current.ok(`${err}`);
      }).finally(() => {
        setLoading(false);
      });
    }
  };


  const saveClientData = (modifiedClient) => {
    if (modifiedClient?.CLIENT_NAME.trim() === '') {
      alert.current.ok('Error', 'Client name cannot be blank');
      return;
    }
    if (modifiedClient?.Client_ID.trim() === '') {
      alert.current.ok('Error', 'Client Id cannot be blank');
      return;
    }
    if (modifiedClient?.PARENT_COMPANY.trim() === '') {
      alert.current.ok('Error', 'Parent Company cannot be blank');
      return;
    }
 


    setLoading(true);
    Api.saveClientData(orgiClientId, modifiedClient).then((result) => {
      if (result.SUCCESS === true) {
        alert.current.ok('Saved', 'Client Data Saved', () => {
          getRemoteClientList();
          modifiedClient.id=result.id;
          modifiedClient.Library_PURL=result.Library_PURL
          getCountrys(modifiedClient);
        });
        setClientToEdit(null);
      } else {
        alert.current.ok('Error', `${result.ERROR}`);
      }
    }).catch((err) => {
      alert.current.ok('error', `${err}`);
    }).finally(
      () => {
        setLoading(false);
      },
    );
  };


  const updateCountry = (modifiedCountry) => {
    setLoading(true);
    modifiedCountry.CLIENT_NAME=selectedClient.CLIENT_NAME;
    Api.updateRemoteCountry(modifiedCountry).then(() => {
      setSelectedCountry(null);
      alert.current.ok('Country updated', `Country updated with name ${modifiedCountry.COUNTRY}.`);
      getCountrys(selectedClient);
    }).catch((err) => {
      alert.current.ok('Err', `${err}`);
    }).finally(() => {
      setLoading(false);
    });
  };


  const addNewCountry = (modifiedCountry) => {
 
    if (modifiedCountry.COUNTRY?.length <= 3) {
      alert.current.ok('Error', 'Country Should be given');
      return;
    }
    setLoading(true);
  
    modifiedCountry.CLIENT_NAME=selectedClient.CLIENT_NAME;
    modifiedCountry.Client_ID= selectedClient.id;
    
    Api.insertRemoteCountry(modifiedCountry).then((result) => {
      if (result.SUCCESS === true) {
        alert.current.ok('Success', 'Country inserted successfully');
        setSelectedCountry(null);
        getCountrys(selectedClient);
      } else {
        alert.current.ok('Error', `${result.ERROR}`);
      }
    }).catch((err) => {
      alert.current.ok('Err', `${err}`);
    }).finally(
      () => { setLoading(false); },
    );
  };

  const insertOrUpdateCountry = (modifiedCountry) => {
    if (actionMode === Constants.MODE_UPDATE) {
      updateCountry(modifiedCountry);
    } else if (actionMode === Constants.MODE_ADD_NEW) {
      addNewCountry(modifiedCountry);
    }
  };


  const deleteCountry = (countryToDelete) => {
    booleanAlert.current.ask('Do you really want to delete', `Are you sure you want to delete the country with name ${countryToDelete.COUNTRY}?`, () => {
      setLoading(true);
      Api.deleteRemoteCountry(countryToDelete.id).then(() => {
        alert.current.ok('Deleted', `${countryToDelete.COUNTRY} deleted successfully!`, () => {
          getCountrys(selectedClient);
        });
      }).catch((err) => {
        alert.current.ok(`${err}`);
      }).finally(() => {
        setLoading(false);
      });
    });
  };



  const deleteClient = () => {
    booleanAlert.current.ask('Do you really want to delete', `Are you sure you want to delete "${selectedClient.CLIENT_NAME}" and all its countries?\n NOT reversible !!`, () => {
      setLoading(true);
      Api.deleteClient(selectedClient.id).then(() => {
        alert.current.ok('Deleted', `${selectedClient.CLIENT_NAME} deleted successfully!`, () => {
          getRemoteClientList();
          setSelectedClient({});
        });
      }).catch((err) => {
        alert.current.ok(`${err}`);
      }).finally(() => {
        setLoading(false);
      });
      
    });
  };


  const addBtnPress = () => {
    setSelectedCountry({
      ...DEFAULT_COUNTRY
     
    });
    setActionMode(Constants.MODE_ADD_NEW);
  };


  return (
    <Container>
      <Grid container spacing={6}>


        <Grid md={6} item>
          
       
          <Autocomplete
            popoverProps={{ style: { width: 'auto' } }}
            style={{ width: '100%' }}
            onChange={(event, item) => {
              getCountrys(item);
            }}
            value={selectedClient}
            options={clients}
            disableClearable
            autoSelect
            renderOption={(option) => (option.Client_ID == Constants.NEW_ID ? <strong>{option.CLIENT_NAME}</strong> : option.CLIENT_NAME)}
            getOptionLabel={(option) => option.CLIENT_NAME}
            renderInput={(params) => <TextField fullWidth {...params} label="Select Client" variant="outlined" />}
          />
         
           
        </Grid>
        <Grid md={6} item>
          <Box display="flex">         
           <TextField
            disabled
            style={{ width: '100%' }}
            placeholder="Client Id"
            label="Client Id"
            value={selectedClient.Client_ID || ''}
            variant="outlined"
          />
           <Button onClick={()=>{
             setShowClientFinder(true);
           }}>
          <FindInPage />
          </Button>
          </Box>
        </Grid>


        <Grid item md={6}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              disabled
              style={{ width: '100%' }}
              label="Client name"
              value={selectedClient.CLIENT_NAME || ''}
            />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField
            disabled
            label="Parent Company"
            style={{ width: '100%' }}
            value={selectedClient.PARENT_COMPANY || ''}
          />
        </Grid>

        <Grid item md={6}>
          <TextField

            disabled
            label="PURL"
            style={{ width: '100%' }}
            value={selectedClient.Library_PURL || ''}
          />
        </Grid>

        <Grid item md={6}>
          {selectedClient.Client_ID && <Button onClick={startEditingClientData} variant="contained" color="primary">Edit Client Data</Button>}
          {selectedClient.Client_ID && <Button  style={{marginLeft:2}} onClick={deleteClient} variant="contained" color="secondary">Delete Client</Button>}
        </Grid>

     
      </Grid>


      {selectedClient?.Client_ID && (
      <Box mt={4}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Countries
          <IconButton onClick={addBtnPress}>

            <AddBoxTwoTone color="primary" />

          </IconButton>
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Contracts</TableCell>
              <TableCell> Country</TableCell>
              <TableCell> Livewell access code</TableCell>
             

              <TableCell>BIOMETRICS_URL</TableCell>
              <TableCell>Phone no.</TableCell>
              <TableCell>...</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {countries.map((country, index) => (
              <RemoteCountryRow
                deleteAction={deleteCountry}
                editAction={(pcountry) => {
                  setActionMode(Constants.MODE_UPDATE);
                  setSelectedCountry(pcountry);
                }}
                index={index}
                key={country.Country_ID}
                country={country}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
)}

                {showClientFinder===true && <ClientFinder
                onSelect={(client)=>{
                  setShowClientFinder(false);
                  setSelectedClient(client);
                  getCountrys(client);
                }}
                clients={clients} open={showClientFinder} afterClose={()=>setShowClientFinder(false)} />}

      <EditCountry
        mode={actionMode}
        saveAction={insertOrUpdateCountry}
        afterClose={() => {
          setSelectedCountry(null);
        }}
        open={selectedCountry !== null}
        param_country={selectedCountry}
      />
      {clientToEdit != null && (
      <ClientDataEditor
        orgiClientId={orgiClientId}
        saveAction={(modifiedClient) => {
          saveClientData(modifiedClient);
        }}
        clientToEdit={clientToEdit}
        closeAction={() => {
          setClientToEdit(null);
        }}
      />
      )}
      <BooleanAlert ref={booleanAlert} />
      <Alert ref={alert} />
      <Backdrop style={{ zIndex: 9000 }} open={loading}>
        <CircularProgress />
      </Backdrop>
    </Container>
  );
};



const RemoteCountryRow = ({
  country, index, editAction, deleteAction,
}) => {
  const formatNumber = () => {
    const numbers = [];
    for (let counter = 1; counter <= 7; counter++) {
      if (country[`Phone_${counter}`]) {
        numbers.push({ phone: country[`Phone_${counter}`], label: country[`Phone_Label_${counter}`] });
      }
    }
    return numbers.map((num) => (
      <div style={{ fontSize: 10 }}>
        {num.phone}
        -
        {num.label}
      </div>
    ));
  };


  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
    
      <TableCell>
        {_.get(country,"contracts[0]","")}
      </TableCell>
      <TableCell>
        {country.COUNTRY}
      </TableCell>
      <TableCell>
        {country.LIVEWELL_ACCESS_CODE}
      </TableCell>
 

      <TableCell>
        {country.BIOMETRICS_URL}
      </TableCell>
      <TableCell>{formatNumber()}</TableCell>
      <TableCell>
        <IconButton onClick={() => {
          editAction(country);
        }}
        >
          <EditIcon color="primary" />
        </IconButton>
        <IconButton onClick={() => {
          deleteAction(country);
        }}
        >
          <DeleteIcon color="secondary" />
        </IconButton>

      </TableCell>
    </TableRow>
  );
};


const ClientDataEditor = ({
  closeAction, clientToEdit, saveAction, orgiClientId,
}) => {
  const [client, setClient] = useState(clientToEdit);
  const [imagePath,setImagePath]=useState('');
 

  useEffect(()=>{
    setImagePath(`${SERVER}/logos/${clientToEdit.Primary_Client_Logo_Global}.jpg`)
  },[])


  const doFileUPload=(aFile)=>{

    const formData = new FormData(); 
      
    formData.append( 
      "logoFile", 
      aFile,
      client.Primary_Client_Logo_Global
    ); 
    Api.uploadFile(formData).then(res=>{
      console.log(res);
    }).catch(err=>{
      console.log(err);
    })
   

  }

  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant="subtitle1">Edit Client data</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <TextField
              onChange={(e) => {
                client.Client_ID = e.target.value;
                setClient({ ...client });
              }}
              value={client.Client_ID}
              fullWidth
              label="Client Id"
            />
          </Grid>

          <Grid item md={12}>
            <TextField
              onChange={(e) => {
                client.CLIENT_NAME = e.target.value;
                setClient({ ...client });
              }}
              label="Client name"
              value={client.CLIENT_NAME}
              fullWidth
            />
          </Grid>

          <Grid item md={12}>
            <TextField
              onChange={(e) => {
                client.PARENT_COMPANY = e.target.value;
                setClient({ ...client });
              }}
              label="Parent Company"
              value={client.PARENT_COMPANY}
              fullWidth
            />
          </Grid>






          <Grid item md={12}>



<div style={{display:'flex'}}>
  
<img src={imagePath+"?rand="+Math.random()} style={{maxWidth:100,marginRight:10}} />

            <TextField
              onChange={(e) => {
                client.Primary_Client_Logo_Global	 = e.target.value;
                setClient({ ...client });
               
              }}
              label="Client Logo Global"
              value={client.Primary_Client_Logo_Global}
              fullWidth
            />


<Button
   style={{width:200}}
  variant="contained"
  component="label"
>
  Upload File
  <input
  onChange={e=>{

    if(!client.Primary_Client_Logo_Global || client.Primary_Client_Logo_Global=='')
    {
      alert("Logo image name must be provided in the \"Client Logo Global\" field prior to upload.")
      return false;
    }
    doFileUPload(e.currentTarget.files[0] );
  }}
accept="image/jpeg"
type="file"
    hidden
  />
</Button>

</div>


          </Grid>

        


      


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            saveAction(client);
          }}
        >
          Save
        </Button>
        <Button onClick={closeAction}>Cancel</Button>

      </DialogActions>
    </Dialog>
  );
};
