/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
} from 'react';
import {
  TextField, Box,
  Typography, Dialog, DialogTitle, DialogContent, Button, DialogActions,
} from '@material-ui/core';


import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Constants from './Constants';


export default ({
  open, afterClose, clients,onSelect
}) => {
  const [allClient, setAllClient] = useState(clients);
  let searchText = '';


  const doSearch = _.debounce(() => {
   
    const newFound = _.filter(clients, (a) => a.Client_ID.toLowerCase().includes(searchText.toLowerCase()) || a.CLIENT_NAME.toLowerCase().includes(searchText.toLowerCase()));
    setAllClient(newFound);
  }, 500);


  /**
   *
   * @param {Event} e
   */
  const searchTextChanged = (e) => {
    const findText = e.target.value;
    searchText = findText;
    doSearch();
  };


  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <Typography variant="subtitle1" color="primary">
          Find Client
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField onChange={searchTextChanged} style={{ width: 550 }} fullWidth label="Client id" />

        <TableContainer fullWidth component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={100}>Client Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell width={100} align="right">Select</TableCell>

              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        <TableContainer style={{ height: 400, overflow: 'auto' }} fullWidth component={Paper}>
          <Table>

            <TableBody>

              {
                  allClient.map((client) => {
                    if (client.Client_ID === Constants.NEW_ID) {
                      return null;
                    }

                    return (
                      <TableRow key={client.Client_ID}>
                        <TableCell width={100}>{client.Client_ID}</TableCell>
                        <TableCell>{client.CLIENT_NAME}</TableCell>
                        <TableCell align="right" width={100}>
                          <Button onClick={() => {
                            onSelect(client);
                          }}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
              }

            </TableBody>
          </Table>
        </TableContainer>


      </DialogContent>
      <DialogActions>
        <Box textAlign="center" marginTop={5}>

          <Button
            onClick={() => {
              afterClose();
            }}
            color="secondary"
          >
            Cancel
          </Button>

        </Box>
      </DialogActions>
    </Dialog>
  );
};
