import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, Button, Box, DialogActions, DialogContentText,
} from '@material-ui/core';

export default class BooleanAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '', body: '', yes: '', no: '',
    };
  }


  ask(title, body, yes = () => {}, no = () => {}) {
    this.setState({
      title, body, yes, no,
    });
    this.setOpen(true);
  }

  setOpen(bool) {
    this.setState({ open: bool });
  }


  render() {
    return (
      <Dialog open={this.state.open}>
        <DialogTitle>{this.state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              this.state.yes();
              this.setOpen(false);
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              this.state.no();
              this.setOpen(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
