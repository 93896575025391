import React, { useState, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
 
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
 
import Api from '../lib/Api';
import Copyright from './Copyright';


const useStyles = makeStyles((theme) => ({

  warning: {
    color: theme.palette.error.main,
    fontWeight: 'bolder',

  },

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    maxWidth:400,
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const txtUsername = useRef();
  const txtPassword = useRef();


  const loginBtnPress = () => {
    (async () => {
      setLoading(true);
      setError(null);
      try {
        const { result } = await Api.admin_login({ username: txtUsername.current.value, password: txtPassword.current.value });
        if (result != 'success') {
          setError('Invalid username or password');
        } else {
          location.replace('/admin/dashboard');
        }
      } catch (err) {
        setError(`${err}`);
      }
      setLoading(false);
    })();
  };


  return (
    <Container component="main" style={{display:'flex'}}   maxWidth="sm">
      <CssBaseline />
      <Box style={{flex:1}}  border={1} padding={10} boxShadow={3} marginTop={10}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            inputRef={txtUsername}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            inputRef={txtPassword}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {error && (
          <Box className={classes.warning} padding={1} color="red">
            {error}
          </Box>
          )}

          <Button
            disabled={loading}
            onClick={loginBtnPress}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login In

          </Button>
          {loading && <CircularProgress />}

        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
}
