import React from 'react';
import styled from 'styled-components';
 

import Heroimg from '../assets/hero-image.png';
import Materials from './Materials';
import HeaderComp from './HeaderComp';
import FooterComp from './FooterComp';
import { MainBody, OrangeRule } from './CommonComp';


const InfoBox = styled.div`
background-color: #efefee;
display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    @media (min-width: 768px) {
        width: 50%;
    }
`;

const InfoHeader = styled.div`
color: #c25608;
margin-bottom: 12px;
    font-size: 24px;
    font-weight:bold;
`;

const InfoText = styled.p`
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 24px;
    font-size: 16px;

`;


const HeroImage = styled.div`
background-image:url(${Heroimg});
background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: block;
    width: 50%;
@media only screen and (max-width: 768px)
{
    display:none;
}

`;


const InfoBoxWrapper = styled.div`
display:flex;
margin-top:30px;
`;


export default ({ client, orders,archived_orders }) => (
  <MainBody>
    <HeaderComp client={client} />
    <InfoBoxWrapper>
      <InfoBox>
        <InfoHeader>Welcome to your Optum International marketing materials!</InfoHeader>
        <InfoText>On this page, you’ll find a variety of marketing materials you can use to drive programme interest and participation across your organisation.</InfoText>
        <InfoText>
          Click on the links below to access each marketing material or asset. Please take consideration when selecting marketing materials to ensure the correct language and country are chosen. You can then download and distribute to your employees. Should you have any questions, please contact your account representative.
        </InfoText>
        <InfoText>
          Your materials have been designed to print.
        </InfoText>

      </InfoBox>

      <HeroImage />
    </InfoBoxWrapper>
    <OrangeRule />

    <Materials archived_orders={archived_orders} orders={orders} />

    <FooterComp />

  </MainBody>
);
