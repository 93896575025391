import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExitToApp from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SwapCalls from '@material-ui/icons/SwapCalls';
import Error from '@material-ui/icons/Error';
import ImportExport from '@material-ui/icons/ImportExport';


import PeopleIcon from '@material-ui/icons/People';
import Api from '../lib/Api';
import Prompt from './Prompt';


export default class ListItems extends React.Component {

    constructor(props)
    {
      super(props);
    
    }


  render() {
    return (
      <div>
        <Prompt ref={(ref) => {
          this.prompt = ref;
        }}
        />

        <ListItem
          button
          onClick={() => {
            this.props.history.push('/admin/clientorder');
          }}
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>


        <ListItem
          button
          onClick={() => {
            this.props.history.push('/admin/client');
          }}
        >
          <ListItemIcon>
            <BusinessCenterIcon />
          </ListItemIcon>
          <ListItemText primary="Clients" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            Api.getCSV();
          }}
        >
          <ListItemIcon>
            <ImportExport />
          </ListItemIcon>
          <ListItemText primary="Export Client CSV" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            this.props.history.push('/admin/menucsv_exports');
            /*
            Api.getCSVDisplay(null);
            this.prompt.open('Product Id', 'Enter Product id', (pid) => {
               Api.getCSVDisplay(pid);
            });
            */
          }}
        >
          <ListItemIcon>
            <ImportExport />
          </ListItemIcon>
          <ListItemText primary="Menu CSV" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            this.props.history.push('/admin/apicalls');
          }}
        >
          <ListItemIcon>
            <SwapCalls />
          </ListItemIcon>
          <ListItemText primary="Api Call Logs" />
        </ListItem>


        <ListItem
          button
          onClick={() => {
            this.props.history.push('/admin/contractIdErrors');
          }}
        >
          <ListItemIcon>
            <Error />
          </ListItemIcon>
          <ListItemText primary="Contract Id Errors" />
        </ListItem>

        <ListItem
          onClick={() => {
            Api.admin_logout().then(() => {
              this.props.history.push('/admin/');
            });
          }}
          button
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>

      </div>
    );
  }
}
