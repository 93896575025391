import React from "react"
import styled from 'styled-components';
import Constants from "./Constants";
const MainBody=styled.div`
    position:relative;
   background-color:${Constants.WHITE};
   padding: 40px 25px;
   min-width: 300px;
   margin-right: auto;
    margin-left: auto;
   @media(min-width: 1200px) {
       width:1170px;
   }
   @media (max-width: 979px) and (min-width: 768px)
   {
    width: 724px;

   }
   @media (max-width: 767px)
   {
    width: auto;

   }

`;


const OrangeRule=styled.div`
width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 10px solid #e87722;`;

    export  {MainBody,OrangeRule}