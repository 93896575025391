import React, { useEffect } from 'react';
import {
  Box, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, TextareaAutosize,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,

} from '@material-ui/pickers';
import moment from 'moment';
import Api from '../lib/Api';


export default () => {
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    Api.getErrorList().then(setRows);
  }, []);


  return (
    <Box>


      <Box>
        <Table>
          <TableHead>
            <TableCell>Date</TableCell>
            <TableCell>Contract Id</TableCell>
            <TableCell>Order id</TableCell>
            <TableCell>Order json</TableCell>
            
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow>
                <TableCell>{row.on_date}</TableCell>
                <TableCell>{row.Contract_ID}</TableCell>
                <TableCell>{row.order_id}</TableCell>
                <TableCell><TextareaAutosize readOnly rowsMax={10} value={row.order_json} /></TableCell>
         
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
