import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  Link,
} from 'react-router-dom';
import HeaderComp from './HeaderComp';
import FooterComp from './FooterComp';
import { MainBody, OrangeRule } from './CommonComp';

import Api, { ASSETS_SERVER } from '../lib/Api';


const BackText = styled(Link)`
color: #005e9d;
text-decoration: none;
text-align:right;
cursor: pointer;
:hover{
    text-decoration: none;
}
@media screen and (min-width: 1200px) {
    margin-right:40px;
}
`;

const SubHeader = styled.div`
 display:flex;
 justify-content:space-between;
 `;

const SubHeaderText = styled.span`
 font-weight: 300;
 text-align: left;
 color: #282a2e;
 font-size: 18px;

 `;


const ResultsHeader = styled.div`
            margin-top:20px;
            border-bottom: 1px solid #ddd;
            padding: 15px 0;
            margin-bottom:10px;
            
        `;


const Results = styled.div`
 
border-bottom: 1px solid #ddd;
 
 
max-width: 980px;
margin: 0 auto;

`;


const Pages = styled.span`
    color: #333;
    font-size:12px;
    padding: 15px 10px;
    margin-left:100px;
    font-size: inherit;
    text-decoration: none;
    border-bottom: 1px solid #333;
    
    `;


const Found = styled.div`
margin: 10px 0 30px;
    color: #888;
    font-size: 12px;
`;


const Searching = styled.div`
margin:0 auto;
max-width: 980px;
text-align:center;
`;


const ResultTitle = styled.a`
color: #005e9d;
text-decoration:none;
cursor: pointer;
`;

const SingleResult = styled.div`
 margin:0 auto;
max-width: 980px;
margin-top:10px;
margin-bottom:30px;
`;

const ResultDesp = styled.div`
font-size:14px;
`;

const ListResult = ({ results, searchQuery }) => (
  <>
    {results.map((result) => {
      let text = `${result.product_name}-${result.language}`;
      text = text.replace(new RegExp(searchQuery, 'ig'), `<b>${searchQuery}</b>`);

      return (
        <SingleResult key={result.id}>
          <ResultTitle target="_blank" href={`${ASSETS_SERVER + result.pdf_local_path}`} dangerouslySetInnerHTML={{ __html: text }} />
          <ResultDesp>{result.product_desc}</ResultDesp>
        </SingleResult>
      );
    })}
  </>
);


export default ({ client, searchQuery }) => {
  const [results, setResults] = useState([]);
  const [time, setTime] = useState(0);
  const [searching, setSearching] = useState(true);


  useEffect(() => {
    let startTime = new Date().getTime();
    Api.searchItems({ searchtext: searchQuery, client_id: client.CLIENT_NAME }).then(({ results, time }) => {
      setResults(results);
    }).catch((err) => {}).finally(() => {
      setSearching(false);
      startTime = new Date().getTime() - startTime;
      let seconds = startTime / 1000;
      seconds = seconds.toFixed(2);
      setTime(seconds);
    });
  }, []);

  return (
    <MainBody>
      <HeaderComp searchQuery={searchQuery} client={client} />
      <OrangeRule />
      <SubHeader>
        <SubHeaderText>Search Results</SubHeaderText>
        <BackText to={`/?token=${client.access_token}`}>&lt;&lt; Go back to previous page</BackText>
      </SubHeader>
      {searching != true && (
        <>
          <ResultsHeader>
            <Pages>Pages</Pages>
          </ResultsHeader>
          <Results>
            <Found>
              {results.length}
              {' '}
              results found for
              {' '}
              <b>
                "{searchQuery}
                "
</b>
              {' '}
              in
              {' '}
              {time}
              {' '}
              seconds
            </Found>
            <ListResult searchQuery={searchQuery} results={results} />
          </Results>
        </>
      )}
      {searching && <Searching>searching...</Searching>}
      <FooterComp />
    </MainBody>
  );
};
