import React,{useEffect, useState} from "react"
import Page from "./Page"
import qs from 'query-string'
import LoadingScreen from "./LoadingScreen";
import Api from "../lib/Api";
import {
    BrowserRouter,
    Switch,
    Route,
    withRouter,
    Link
  } from "react-router-dom";
import SearchPage from "./SearchPage";


export default ()=>{
 

    const [pageLoaded,setPageLoaded]=useState(false);
    const [error,SetError]=useState(false)
    const [clientData,setClientData]=useState({})
    const [orders,setOrders]=useState([]);
    const [archived_orders,setArchivedOrders]=useState([]);
    
    const [searchQuery,setSearchQuery]=useState('');

    useEffect(()=>{
            const vars=qs.parse(location.search);
            if(vars.token==undefined || vars.token?.trim().length==0)
            {
                SetError("No Token given");
                return;
            }
            if(vars.q)
            {
                setSearchQuery(vars.q);
            }
            Api.getClientByToken(vars.token).then(result=>{
                if(result?.SUCCESS===true)
                {
                    setClientData(result.CLIENT);   
                    setOrders(result.ORDERS) ;
                    setArchivedOrders(result.ARCHIVED_ORDERS);
                   
                    setPageLoaded(true);
                        
                }
                else{
                    SetError("No client with given token");
                }
            }).catch(err=>{
                SetError(err+"");
            })


    },[]);


     if(error!==false)     
     {
         return <div>{error}</div>
     }  

    if(pageLoaded===true)
    {
        return   (<Switch>
        <Route exact path="/" render={props=><Page client={clientData} archived_orders={archived_orders} orders={orders} />} />   

        <Route exact path="/search" render={props=><SearchPage client={clientData} searchQuery={searchQuery}   />}  />             
         </Switch>)
        
        
    }
    else
    {
        return <LoadingScreen/>
    }





}